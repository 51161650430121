<template>
    <div>
        <div class="container auth-page">
            <form
                method="post"
                class=""
                @submit.prevent="onLoginSubmit"
            >
                <h2>Авторизация</h2>
                <div class="">
                    <input
                        type="password"
                        class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                        placeholder="Password"
                        autocomplete="off"
                        v-model="password"
                    />
                </div>
                <div class="error-message">{{errorMessage}}</div>
                <div class="intro-x text-center">
                    <button
                        type="submit"
                        class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                    >
                        Войти
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {mapActions, mapGetters} from 'vuex'
import cash from "cash-dom/dist/cash";
import authService from "../../services/auth.service";

export default {
    setup() {
        const password = ref('');
        const errorMessage = ref(null);

        return {
            errorMessage,
            password,
        };
    },
    computed: {
        ...mapGetters({getUser: 'user/getUser'})
    },
    methods: {
        async onLoginSubmit() {
            try {
                const formData = {
                    password: this.password,
                };

                if (localStorage.token) {
                    localStorage.removeItem('token');
                }

                await this.axios.post('/auth', formData)
                    .then(res => {
                        this.loading = false;

                        if (res.data.token) {
                            localStorage.setItem('token', res.data.token)
                            this.$router.push('/');
                        }

                    })
                    .catch(ex => {
                        if (ex.response.data.message !== undefined) {
                            this.errorMessage = ex.response.data.message;
                        }
                    });

            } catch (e) {
                console.log('cant auth: ' + e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.auth-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    form {
        text-align: center;
        max-width: 600px !important;

        h2 {
            font-size: 26px;
            margin-bottom: 30px;
        }

        button {
            margin: 0;
        }
    }

    .error-message {
        color: red;
        height: 20px;
        margin: 10px 0;
    }
}
</style>