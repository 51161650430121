import Tippy from './tippy/Main.vue';
import TippyContent from './tippy-content/Main.vue';
import CKEditor from './ckeditor/Main.vue';
import * as featherIcons from '@zhuowenli/vue-feather-icons';

export default app => {
  // app.component('Highlight', Highlight);
  app.component('Tippy', Tippy);
  app.component('TippyContent', TippyContent);
  app.component('CKEditor', CKEditor);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24';
    app.component(key, icon);
  }
}
