import authService from '../services/auth.service';
import router from '../router/index';

const state = () => ({
  isAuthenticated: false,
  access_token: localStorage.getItem('access_token'),
  refresh_token: localStorage.getItem('refresh_token'),
  userData: {
    id: null,
    is_blocked: false,
    role_title: null,
    isLogged: false,
    name: null,
    surname: null,
    email: null,
    image: null,
    role: null,
    _refresh: null,
    _token: null
  },
  userAccessRules: {},
  userRole: null,
  fakeUsersMap: {
    'admin@volga.com': 0,
    'manager@volga.com': 1,
    'customer@volga.com': 2,
  },
  fakeTokensMap: {
    'admin_token': 0,
    'manager_token': 1,
    'customer_token': 2
  },
  fakeUserData: [
    {
      name: 'Vasili',
      surname: 'Pupkin',
      email: 'admin@volga.com',
      image: 'https://via.placeholder.com/150',
      role: 'admin',
      _refresh: 'my_refresh_token',
      _access: 'admin_token',
      access_rules: {
        pages: {
          create: true,
          update: true,
          delete: true,
        },
        users: {
          create: true,
          update: true,
          delete: true,
        },
        booking: {
          create: true,
          update: true,
          delete: true
        },
        cruises: {
          create: false,
          update: false,
          delete: false,
        },
      }
    },
    {
      name: 'Vasili',
      surname: 'Pupkin',
      email: 'manager@volga.com',
      image: 'https://via.placeholder.com/150',
      role: 'manager',
      _refresh: 'my_refresh_token',
      _access: 'manager_token',
      access_rules: {
        pages: {
          create: true,
          update: true,
          delete: true,
        },
        booking: {
          create: true,
          update: true,
          delete: true
        },
        cruises: {
          create: false,
          update: false,
          delete: false,
        },
      }
    },
    {
      name: 'Vasili',
      surname: 'Pupkin',
      email: 'customer@volga.com',
      image: 'https://via.placeholder.com/150',
      role: 'customer',
      _refresh: 'my_refresh_token',
      _access: 'customer_token',
      access_rules: {
        cruises: {
          create: false,
          update: false,
          delete: false,
        },
        booking: {
          create: true,
          update: true,
        }
      }
    }
  ]
})

const mutations = {
  setUserData(state, payload) {
    for (const key of Object.keys(state.userData)) {
      state.userData[key] = payload.user[key]
    }
    state.access_token = payload.token
    localStorage.setItem('access_token', payload.token)
    state.isAuthenticated = true
  },
  setRefreshUserData(state, payload) {
    for (const key of Object.keys(payload)) {
      state.userData[key] = payload[key]
    }
    state.isAuthenticated = true;
  },
  clearUserData(state) {
    state.userData = {
      id: null,
      is_blocked: false,
      role_title: null,
      isLogged: false,
      name: null,
      surname: null,
      email: null,
      image: null,
      role: null,
      _refresh: null,
      _token: null
    }
  },
  clearTokens(state) {
    state.refresh_token = null
    state.access_token = null
    localStorage.removeItem('access_token');
  }
};

const getters = {
  getUser: state => state.userData,
  getToken: state => state.userData._token,
  getFakes: state => state.fakeUserData,
  isAuthenticated: state => state.isAuthenticated,
  getUserRole: state => state.userData.role,
  getAccessToken: state => state.access_token,
  getRefreshToken: state => state.refresh_token,
  getUserAccessRules: state => state.userAccessRules
}

const actions = {
  async logOut({ commit }) {
    try {
      await authService.logout(commit);
      await router.push({name: 'login'})
    } catch (ex) {
      console.log('cant logout: ' + ex);
    }
  },
  async login({state, commit, getters}, payload) {
    try {
      const user = await authService.login({state, commit, getters}, payload);
      if (user) commit('setUserData', user);
    } catch (ex) {
      console.error('cant login: ' + ex);
    }
  },

  async verifyToken({state, getters, commit}) {
    try {
      const user = await authService.verifyToken({state, getters, commit});
      if (user) {
        commit('setRefreshUserData', user);
      }
      return user;
    } catch (ex) {
      console.error('cant verify token: ' + ex);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
