import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import globalComponents from './global-components';
import Notifications from '@kyvg/vue3-notification';
import Maska from 'maska';
import utils from './utils';
import './libs';
// SASS Theme
import '@vueform/multiselect/themes/default.css';
import './assets/sass/app.scss';


// const token = localStorage.getItem('access_token');

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (config) => {

      if (router.currentRoute.value.path.includes('admin') || config.url.includes('/profile')) {
          let token = localStorage.getItem('access_token');
          if (token) config.headers['Authorization'] = `Bearer ${ token }`;
          return config;
      }

    let token = localStorage.getItem('token');
    if (token) config.headers['Authorization'] = `Bearer ${ token }`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  async function(error) {
    if (error) {
      if (error.response.status === 401 || error.response.status === 403) {

          if (router.currentRoute.value.path.includes('/admin')) {
              localStorage.removeItem('access_token');
              await router.push('/login');
          }

        localStorage.removeItem('token');
        await router.push('/auth');
      }
    }

    console.log('inside inteceptor: ', error);
    throw error;
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(Maska)
  .use(Notifications);

globalComponents(app);
utils(app);

app.provide('axios', axios);
app.provide('VueAxios', VueAxios);

app.mount('#app');
