import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';

import SideMenu from '@/layouts/side-menu/Main.vue';
import DefaultLayout from '@/layouts/default/DefaultLayout.vue';
import Login from '@/views/login/Main.vue';
import Auth from '@/views/auth/Main.vue';
import ErrorPage from '@/views/error-page/Main.vue';

const routes = [
  {
    path: '/admin',
    component: SideMenu,
    meta: {
      auth: ['super-admin', 'admin', 'manager'],
    },
    children: [
      {
        path: '/admin',
        name: 'admin',
        meta: {
          auth: ['super-admin', 'admin', 'manager'],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Main.vue'),
      },
      {
        path: '/admin/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/Main.vue'),
        meta: {
          auth: ['super-admin', 'admin'],
        },
      },
      {
        path: '/admin/users/create',
        name: 'userCreate',
        component: () => import(/* webpackChunkName: "user-create" */ '@/views/users/Create.vue'),
        meta: {
          auth: ['super-admin', 'admin'],
        },
      },
      {
        path: '/admin/users/:id/edit',
        name: 'userEdit',
        component: () => import(/* webpackChunkName: "user-edit" */ '@/views/users/Edit.vue'),
        meta: {
          auth: ['super-admin', 'admin'],
        },
      },
      {
        path: '/admin/pages',
        name: 'pages',
        component: () => import(/* webpackChunkName: "pages" */ '@/views/pages/Main.vue'),
        meta: {
          auth: ['super-admin', 'admin', 'manager'],
        },
      },
      {
        path: '/admin/pages/create',
        name: 'pageCreate',
        component: () => import(/* webpackChunkName: "pages" */ '@/views/pages/Create.vue'),
        meta: {
          auth: ['super-admin', 'admin', 'manager'],
        },
      },
      {
        path: '/admin/pages/:id/edit',
        name: 'pageEdit',
        component: () => import(/* webpackChunkName: "pages" */ '@/views/pages/Edit.vue'),
        meta: {
          auth: ['super-admin', 'admin', 'manager'],
        },
      },

      // --- settings

      {
        path: '/admin/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "pages" */ '@/views/settings/Main.vue'),
        meta: {
          auth: ['super-admin', 'admin'],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage,
  },
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "tab" */ '@/views/website/home/Main.vue'),
      },
      // {
      //   path: '/tab/:id',
      //   name: 'tab',
      //   component: () => import(/* webpackChunkName: "tab" */ '@/views/website/home/Main.vue'),
      // },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: DefaultLayout,
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: "content",
        component: () => import(/* webpackChunkName: "content" */ '@/views/website/content/Main.vue'),
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});


router.beforeEach(async (to, from, next) => {
  // общедоступные роуты
  // /:pathMatch(.*)*
  const allowed_routes = ['/login', '/auth', '/error-page'];

  if (allowed_routes.includes(to.path)) {
    return next();
  }

  if (!to.path.includes('/admin')) {
    const token = localStorage.getItem('token');

    if (!token) {
      return next({ path: '/auth' });
    }

    return next();
  }

  const admin_token = localStorage.getItem('access_token');
  const currentUser = store.getters['user/isAuthenticated'];

  // если токена нет, кидаем на авторизацию
  if (!admin_token) {
    return next({ path: '/login' });
  }

  if (!currentUser) {
    try {
      await store.dispatch('user/verifyToken');
    } catch (ex) {
      console.error('cant verify user by token: ' + ex);
    }
  }

  // получение ролей
  const userAccessRule = store.getters['user/getUserRole'];
  const { auth: pageRoles } = to.meta;

  if (pageRoles && pageRoles.length > 0) {
    if (pageRoles.includes(userAccessRule)) {
      return next();
    }

    console.error('ACCESS RULES REJECTED');
    return next({ name: 'error-page' });
  }

  next();
});

export default router;
